import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../db/firebaseConnection";
const db = getFirestore(app);

export const getSingleDestination = async (id) => {
  try {
    return new Promise((resolve, reject) => {
      const newCityRef = doc(db, "Destination", id);
      getDoc(newCityRef)
        .then((data) => {
          if (data.exists()) {
            // console.log("Data Fetched Successfully", data.data());
            resolve({
              error: false,
              Status: 200,
              message: "Record fetched successfully",
              data: data.data(),
            });
            // console.log("Document data:", docSnap.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            resolve({
              error: false,
              Status: 404,
              message: "No record found",
              data: "",
            });
          }
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("Document Fecthed Failure!!!", errorCode, errorMessage);
          resolve({
            error: false,
            Status: 401,
            message: "Document fetched failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
