import { createSlice } from "@reduxjs/toolkit";
import { getAllDeparturepoint } from "../../services/api_services/viewAllDeparturepointService";
import { getAllPinpoint } from "../../services/api_services/viewAllPinpointService";
import { getSingleDeparturepoint } from "../../services/api_services/viewSingleDeparturepointService";
import { getSingleDestination } from "../../services/api_services/viewSingleDestinationService";
import { dispatch } from "../store";

const initialState = {
  data: [],
  onedestination: {},
  departures: [],
  setdestination: {},
  onedeparture: {},
  allPinPoints: [],
  isLoading: false,
  selectedPin: null,
};

const slice = createSlice({
  name: "routeData",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    GetOneDestination(state, action) {
      state.onedestination = action.payload;
    },
    setOneDestination(state, action) {
      state.setdestination = action.payload;
    },
    setOneDeparture(state, action) {
      state.onedeparture = { ...action.payload };
    },
    GetAllDeparture(state, action) {
      state.departures = action.payload;
    },
    GetAllPinPoints(state, action) {
      state.allPinPoints = action.payload;
    },

    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    setSelectedPin(state, action) {
      state.selectedPin = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setOneDeparture, GetAllPinPoints, setSelectedPin ,setOneDestination } =
  slice.actions;
// Actions

export const getOneDestination = async (id) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await getSingleDestination(id);
    if (response.Status === 200) {
      dispatch(slice.actions.GetOneDestination({ ...response?.data, id }));
      dispatch(slice.actions.setOneDestination({ ...response?.data, id }));
      dispatch(slice.actions.stopLoading());
    }else if (response.Status === 404 && response.message === "No record found") {
      dispatch(slice.actions.GetOneDestination({ message: response.message }));
      dispatch(slice.actions.setOneDestination({ message: response.message }));
      dispatch(slice.actions.stopLoading());
    }
  } catch (error) {
    dispatch(slice.actions.stopLoading());
  }
};
export const getOneDeparture = async (id) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await getSingleDeparturepoint(id);
    if (response.Status === 200) {
      dispatch(slice.actions.setOneDeparture({ ...response?.data, id }));
      dispatch(slice.actions.stopLoading());
    }
  } catch (error) {
    dispatch(slice.actions.stopLoading());
  }
};
export const getAllDeparture = async (id) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await getAllDeparturepoint(id);
    if (response.Status === 200) {
      dispatch(slice.actions.GetAllDeparture(response?.data));
      dispatch(slice.actions.stopLoading());
    }
  } catch (error) {
    dispatch(slice.actions.stopLoading());
  }
};

export const getAllPin = async (id) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await getAllPinpoint(id);
    if (response.Status === 200) {
      const pins = [];
      response?.data?.forEach((pin) => {
        const pinData = pin.data();
        const pinImage = pinData.image;
        const pinPosition = {
          lat: pinData.pin_lat,
          lng: pinData.pin_lng,
        };
        const orientation = pinData.orientation;

        pins.push({
          pinImage,
          pinPosition,
          orientation,
          id: pin.id,
          creationdate: pinData.creationdate,
        });


      });
      pins.sort(function (a, b) {
        return new Date(a.creationdate) - new Date(b.creationdate);
      });
      dispatch(slice.actions.GetAllPinPoints(pins));
      dispatch(slice.actions.stopLoading());
    }
  } catch (error) {
    dispatch(slice.actions.stopLoading());
  }
};
