import React, { useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import NodataFound from "../components/NoData/NodataFound";
import { getAllPin } from "../redux/slices/routeData";
import ImageList from "./Home/ImgeList";

const List = () => {
  const params = useParams();
  const { allPinPoints, selectedPin } = useSelector(
    (state) => ({
      allPinPoints: state.routeData.allPinPoints,
      selectedPin: state.routeData.selectedPin,
    }),
    shallowEqual
  );
  const getPinPoints = async () => {
    await getAllPin(params?.id);
  };
  useEffect(() => {
    if (params.id) getPinPoints();
  }, [params.id]);

  let imageRef = useRef([]);
  imageRef.current = allPinPoints.map(
    (ref, index) => (imageRef.current[index] = React.createRef(null))
  );
  const handleScrollTo = () => {
    imageRef?.current?.forEach((element) => {
      console.log(element?.current?.id);
      if (element?.current?.id === selectedPin) {
        // element?.current?.scrollIntoView(
        //   { 
        //     block: "end",
        //     inline: "end",
        //     behavior: "smooth",
        //     alignToTop: false,
        //   }
        // );
      }
    });

  };

  useEffect(() => {
    if (imageRef?.current?.length && selectedPin) handleScrollTo();
  }, [selectedPin, imageRef]);

  return (
    <div className='ps-2 pe-1 pt-2 mobile-list-container'>
      {allPinPoints.length > 0 ? (
        <ImageList
          allPinPoints={allPinPoints}
          imageRef={imageRef}
          cardClick={() => {}}
          selectedPin={selectedPin}
        />
      ) : (
        <NodataFound />
      )}
    </div>
  );
};

export default List;
