import React from "react";
import Page404StyleWrapper from "./Page404.styles";
import { useNavigate } from "react-router-dom";
import image404 from '../assets/images/image-404.png'

function Page404() {
    const navigate = useNavigate();
    return (
        <Page404StyleWrapper>
            <div>
                <div className="image-wrapper">
                    <img src={image404} alt="404image"  />
                </div>
                <div className="content-page">
                    <h1 className="super-title title">Whoops! Page Not Found!</h1>
                    <p>
                        Sorry we can't find the page you are looking for! Please try something
                        else.
                    </p>
                </div>
            </div>
        </Page404StyleWrapper>
    );
}

export default Page404;
