export const generateHouses = (position) => {
  const _houses = [];
  for (let i = 0; i < 100; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    });
  }
  return _houses;
};


let direction = 'ltr';
if (typeof window !== 'undefined') {
  direction = document.getElementsByTagName('html')[0].getAttribute('dir');
}

export const withDirection = (Component) => (props) => {
  return <Component {...props} data-rtl={direction} />;
};
