import React from "react";
import { back, webAppTitle } from "../../staticData/StaticData";
import "./LeftSideListPanel.scss";
import { ReactComponent as BackArrow } from "../../assets/svgs/backArrow.svg";

const LeftSideListPanel = (props) => {
  return (
    <div className='left-panel-container'>
      <div className='position-relative'>
        {props?.showBackArrow && props.windowWidth > 600 ? (
          <div
            className='d-flex align-items-center p-2 cursor-pointer'
            style={{ top: "0px", zIndex: 33, backgroundColor: '#3C3C3C', width: '100%', padding: '10px', position: 'fixed', width: '24%' }}
            onClick={props?.goBack}
          >
            
            <BackArrow height={20} width={20} />
            <p className='text-xs-bold ms-1 mb-1'>{back}</p>
          </div>
        ) : null}
      </div>
      <div className='sub-left-panel-container'>{props.children}</div>
    </div>
  );
};

export default LeftSideListPanel;
