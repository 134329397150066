import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import './Carousel.scss'
import { Keyboard, Navigation } from 'swiper'

export const Carousel = (props) => {
  const { navigation, data, renderComponent, initialSlide = 0, setSelectedPin, cancel } = props
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [showNavigation, setShowNavigation] = useState(false)

  const slideTo = (index) => thumbsSwiper.slideTo(index);

  useEffect(() => {
    setTimeout(() => {
      if (slideTo) {
        slideTo(initialSlide)
        setSelectedPin(data[initialSlide].id)
      }
    }, 500)
    console.log("initialSlide=>", initialSlide)
  }, [initialSlide])
  const [isStart, setIsStart] = useState(true)
  const [count, setCount] = useState(1)
  const [isEnd, setIsEnd] = useState(false)
  const handlePrevios = () => {
    if (thumbsSwiper?.activeIndex === 0 && thumbsSwiper?.activeIndex === count - 1) {
      cancel(false)
    } else {
      setCount(count - 1)
    }
  }

  useEffect(() => {
    if (props.initialSlide) {
      setCount(props.initialSlide + 1)
    }
  }, [props.initialSlide])

  const SwiperButtonNext = () => {
    const swiper = useSwiper()
    return (
      <div
        className={`next-button ${isEnd ? 'prev-next-button-disbled' : ''}`}
        // onClick={() => swiper.slideNext()}
        onClick={() => {
          swiper.slideNext()
          setCount(count + 1)
        }}>
        next
      </div>
    )
  }
  const SwiperButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <div
        className={`prev-button`}
        // onClick={() => swiper.slidePrev()}
        onClick={() => {
          swiper.slidePrev()
          handlePrevios()
        }}>
        back
      </div>
    )
  }
  useEffect(() => {
    setShowNavigation(true)
    setTimeout(() => {
      setShowNavigation(false)
    }, 2000)
  }, [])

  return (
    <div className='carousel-main-container'>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        slidesPerGroup={1}
        onSwiper={setThumbsSwiper}
        loopFillGroupWithBlank={true}
        navigation={navigation}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 5,
          },
        }}
        modules={[Keyboard, Navigation]}
        className='mySwiper'
        initialSlide={initialSlide}
        onTransitionEnd={(swiper) => {
          setSelectedPin && setSelectedPin(data[swiper.realIndex].id)
          if (swiper.isEnd) {
            setIsEnd(swiper.isEnd)
            setIsStart(false)
          } else if (swiper.isBeginning) {
            setIsStart(swiper.isBeginning)
            setIsEnd(false)
          } else {
            if (!swiper.isBeginning && !swiper.isEnd) {
              setIsEnd(false)
              setIsStart(false)
            }
          }
        }}>
        <div
          className='carousel-header'
          style={{ opacity: showNavigation ? 1 : 0 }}>
          <div className='carousel-action'>
            <SwiperButtonPrev />
            <SwiperButtonNext />
          </div>
        </div>

        {data.map((item, index) => {
          return <SwiperSlide key={index.toString()}>{renderComponent(item, index)}</SwiperSlide>
        })}
      </Swiper>
    </div>
  )
}

export default Carousel
