import {
  collectionGroup,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { app } from "../db/firebaseConnection";
const db = getFirestore(app);

export const getAllDeparturepoint = async (destination_id) => {
  try {
    return new Promise(async (resolve, reject) => {
      const museums = query(
        collectionGroup(db, "Route"),
        orderBy("creationdate", "desc"),
        where("destination_id", "==", destination_id)
      );
      let data = await getDocs(museums);
      const demo = [];
      data.forEach((i) => demo.push({ ...i.data(), id: i.id }));
      let finalArray = [];
      for (let i = 0; i < demo.length; i++) {
        const doc = demo[i];
        const museums1 = query(
          collectionGroup(db, "Pin"),
          orderBy("creationdate", "desc"),
          where("routeinfo_id", "==", doc.id)
        );
        let data1 = await getDocs(museums1);
        if (data1._snapshot.docChanges.length > 0) {
          finalArray.push({
            id: doc.id,
            departure_search: doc.departure_search,
            destination_id: doc.destination_id,
            transpotation_mode: doc.transpotation_mode,
            departure_point: doc.departure_point,
            departure_point_english: doc.departure_point_english,
            departure_lat: doc.departure_lat,
            creationdate: doc.creationdate,
            departure_lng: doc.departure_lng,
            departure_point_postal_code: doc.departure_point_postal_code,
            route_information: doc.route_information,
            address: doc.address,
            exit_landmark_english: doc.exit_landmark_english,
            exit_landmark_local: doc.exit_landmark_local,
            path_lat_long_array: doc.path_lat_long_array,
            zoomlevel: doc.zoomlevel,
            pinlength: data1._snapshot.docChanges.length,
          });


        }
      }
      resolve({
        error: false,
        Status: 200,
        message: "Departure point fetched successfully!!!",
        data: finalArray,
      });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
