import styled from 'styled-components';
import { withDirection } from '../utils/commonFunction';

const Page404StyleWrapper = styled.div`
  text-align: center;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  
  .image-wrapper {
    text-align: center;
    img {
        height: 40vh;
        width: 40vh;
    }
  }
  .content-page {
    margin-top: 45px;
    @media screen and (max-width: 1600px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 1440px) {
      margin-top: 35px;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 30px;
    }
    p {
      margin: 15px 16px 45px 16px;
      font-size: 12px;
      color: #2d2c2c;
   
      
    }
    .fill {
      margin: auto;
    }
  }
`;
export default withDirection(Page404StyleWrapper);
