
const assert = require("assert");



const {
  REACT_APP_PORT,
  REACT_APP_HOST,
  REACT_APP_HOST_URL,
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_USER_URL,
} = process.env;

// adding init assertions
assert(REACT_APP_PORT, "Application port is required");
assert(REACT_APP_HOST_URL, "Service endpoint is required");
assert(REACT_APP_DATABASE_URL, "Firebase database endpoint is required");
assert(REACT_APP_PROJECT_ID, "Firebase project id is required");
assert(REACT_APP_APP_ID, "Firebase app id is required");

module.exports = {
  port: REACT_APP_PORT,
  host: REACT_APP_HOST,
  url: REACT_APP_HOST_URL,
  shareURL: REACT_APP_USER_URL,
  firebaseConfig: {
    apiKey: REACT_APP_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_APP_ID,
  },
};
