import React, { useState } from "react";
import { ReactComponent as Home } from "../../assets/svgs/home.svg";
import { ReactComponent as Group } from "../../assets/svgs/Group.svg";
import { ReactComponent as Vector } from "../../assets/svgs/Vector.svg";
import { ReactComponent as Map } from "../../assets/svgs/route.svg";
import { ReactComponent as List } from "../../assets/svgs/list.svg";
import { ReactComponent as Share } from "../../assets/svgs/share.svg";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.scss";
import { shallowEqual, useSelector } from "react-redux";
import { webAppTitle } from "../../staticData/StaticData";
import {
  GetAllPinPoints,
  setOneDeparture,
  setSelectedPin,
} from "../../redux/slices/routeData";
import { dispatch } from "../../redux/store";
import { shareURL } from "../../services/db/config";


function Navbar() {
  const { onedeparture, onedestination } = useSelector(
    (state) => ({
      onedeparture: state.routeData.onedeparture,
      onedestination: state.routeData.onedestination,
    }),
    shallowEqual
  );

  const location = useLocation();
  const [isShare, setIsShare] = useState(false);

  const share = () => {
    const url = `${shareURL}${onedestination.id}`;
    const shareData = {
      title: webAppTitle,
      url,
    };
    if(navigator?.share){      
      navigator
        .share(shareData)
        .then(() => { })
        .catch((e) => console.log("error share", e));
    }else{
      console.log("web share api not supported");
    }
  };

  return (
    <div className='mobile-nav-wrapper'>
    <nav className='mobile-nav'>
      <Link
        to={`/${onedestination.id}`}
        className={`bloc-icon text-xs d-flex flex-column ${location.pathname.split("/").length === 2 && !isShare
            ? "active"
            : ""
          }`}
        onClick={() => {
          setIsShare(false);
          dispatch(setOneDeparture({}));
          dispatch(GetAllPinPoints([]));
          dispatch(setSelectedPin(null));
        }}
      >
        <Home />
        Home
      </Link>
      <Link
        to={onedeparture.id ? `/map/${onedeparture.id}` : null}
        className={`bloc-icon text-xs d-flex flex-column ${location.pathname.includes("/map") && !isShare ? "active" : ""
          } ${onedeparture.id ? null : "disable-text"}`}
        onClick={() => {
          onedeparture.id && setIsShare(false);
        }}
      >
        {onedeparture.id ? <Map /> : <Vector />}
        Map
      </Link>
      <Link
        to={onedeparture.id ? `/list/${onedeparture.id}` : null}
        className={`bloc-icon text-xs d-flex flex-column ${location.pathname.includes("/list") && !isShare ? "active" : ""
          } ${onedeparture.id ? null : "disable-text"}`}
        onClick={() => {
          onedeparture.id && setIsShare(false);
        }}
      >
        {onedeparture.id ? <List /> : <Group />}
        List
      </Link>
      <div
        className={`bloc-icon text-xs d-flex flex-column ${isShare ? "active" : ""
          } `}
        onBlur={() => {
          setIsShare(false);
        }}
        onClick={() => {
          setIsShare(true);
          share();
        }}
      >
        <Share />
        Share
      </div>
    </nav>
    </div>
  );
}

export default Navbar;
