import React from "react";
import "./Marker.scss";

const Marker = (props) => {
  const { index, title, setDirection, selectedPin, pinId } = props;

  return (
    <div
      className='marker'
      style={{
        cursor: "pointer",
        color: selectedPin === pinId ? "#0000E3" : "#fff",
        backgroundColor: selectedPin === pinId ? "#fff" : "#0000E3",
        border: `2px solid ${selectedPin === pinId ? "#0000E3" : "#fff"}`,
      }}
      title={title}
      onClick={setDirection}
    >
      {index}
    </div>
  );
};

export default Marker;
