import React, { useEffect, useMemo, useRef, useState } from "react";
import LeftSideListPanel from "../../components/LeftSideListpanel/LeftSideListPanel";
import RouteList from "./RouteList";
import ImageList from "./ImgeList";
import GoogleMapComponent from "../../components/GoogleMapComponent/GoogleMapComponent";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAllDeparture,
  GetAllPinPoints,
  getOneDestination,
  setOneDeparture,
} from "../../redux/slices/routeData";
import Loader from "../../components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";

const Home = ({ windowWidth }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);
  const { isLoading, allPinPoints, onedestination, onedeparture , setdestination} = useSelector(
    (state) => ({
      isLoading: state.routeData.isLoading,
      allPinPoints: state.routeData.allPinPoints,
      onedestination: state.routeData.onedestination,
      onedeparture: state.routeData.onedeparture,
      setdestination: state.routeData.setdestination,
    }),
    shallowEqual
  );

  let imageRef = useRef([]);

  imageRef.current = allPinPoints.map(
    (ref, index) => (imageRef.current[index] = React.createRef(null))
  );

  // useEffect(() => {
  //   if (params.id && onedestination.id && params.id !== onedestination.id) {
  //     navigate('/404')
  //   }
  // }, [params.id, onedestination.id])

  useEffect(() => {
    if (params && params.id && setdestination && setdestination.message === "No record found") {
      navigate('/404')
    }
  }, [params, setdestination])


  useEffect(() => {
    getOneDestination(params.id);
    getAllDeparture(params.id);
  }, [params?.id]);
  const currentCenter = useMemo(() => {
    if (selectedPin) {
      const selectedPinDetails = allPinPoints.find(
        (item) => item.id === selectedPin
      );


      console.log(">>>>>>>>>>selected ", selectedPinDetails?.pinPosition)

      return selectedPinDetails?.pinPosition;
    } else {

      console.log(">>>>>>>>>>allpinns ", allPinPoints)
      // return allPinPoints[0].allPinPoints;

      if (allPinPoints.length > 0) {
        return allPinPoints[0].pinPosition;
      } else {
        return {
          lat: onedestination?.destination_lat,
          lng: onedestination?.destination_lng,
        };
      }

    }
  }, [selectedPin, allPinPoints, onedestination]);

  return (
    <>
      {isLoading && <Loader />}
      <div className='d-flex home-container' style={{}}>
        <div className='left-container'>
          <LeftSideListPanel
            showBackArrow={showBackArrow}
            goBack={() => {
              setShowBackArrow(false);
              dispatch(setOneDeparture({}));
              dispatch(GetAllPinPoints([]));
              setSelectedPin(null);
            }}
            windowWidth={windowWidth}
          >
            {!showBackArrow || windowWidth < 600 ? (
              <RouteList
                windowWidth={windowWidth}
                showSideImages={() => setShowBackArrow(true)}
              />
            ) : allPinPoints.length > 0 ? (
              <ImageList
                imageRef={imageRef}
                allPinPoints={allPinPoints}
                selectedPin={selectedPin}
                cardClick={(e) => setSelectedPin(e.id)}
              />
            ) : (
              <p className='text-center mt-5'>No data found</p>
            )}
          </LeftSideListPanel>
        </div>
        <div className='right-container'>
          <div style={{ width: "100%", height: "100vh" }}>
            <GoogleMapComponent
              showSideImages={() => setShowBackArrow(true)}
              allPinPoints={allPinPoints}
              destination={{
                lat: onedestination?.destination_lat,
                lng: onedestination?.destination_lng,
              }}
              center={currentCenter}
              departure={onedeparture}
              selectedPin={selectedPin}
              setSelectedPin={setSelectedPin}
              imageRef={imageRef}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
