import {
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../db/firebaseConnection";
const db = getFirestore(app);

export const getAllPinpoint = async (routeinfo_id) => {
  try {
    return new Promise((resolve, reject) => {
      const museums = query(
        collectionGroup(db, "Pin"),
        where("routeinfo_id", "==", routeinfo_id)
      );
      getDocs(museums)
        .then((data) => {
          resolve({
            error: false,
            Status: 200,
            message: "Pin point fetched successfully",
            data: data,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // console.log("Pin point Fecthed Failure!!!",errorCode,errorMessage)
          resolve({
            error: false,
            Status: 401,
            message: "Pin point fetched failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
