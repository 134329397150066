import React from "react";
import "./Loader.scss";
import { ThreeDots } from "react-loader-spinner";
function Loader() {
  return (
    <div className='overlay'>
      <div className='loader'>
        <ThreeDots color='#0000e3' />
      </div>
    </div>
  );
}

export default Loader;
