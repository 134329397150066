import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize('G-5DF8B0FNH1')
    console.log(location.pathname + location.search)
    ReactGA.send({
      hitType: 'pageview',
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    })
  }, [location])
}

export default usePageTracking
