import React from "react";
import "./ImageCard.scss";
import { ReactComponent as ThreeDViewRight } from "../../assets/svgs/imageOrientationRight.svg";
import { ReactComponent as ThreeDViewLeft } from "../../assets/svgs/imageOrientationleft.svg";

function ImageCard({ data, selectedPin = "" }) {
  return (
    <div className='image-card-container'>
      <img className='location-img' src={data.pinImage} alt='loaction' />
      <div className='view-container d-flex justify-content-between'>
        <div
          className='view-text'
          style={{
            color: selectedPin && selectedPin === data.id ? "#0000E3" : "#fff",
            backgroundColor:
              selectedPin && selectedPin === data.id ? "#fff" : "#0000E3",
            border: `2px solid ${
              selectedPin && selectedPin === data.id ? "#0000E3" : "#fff"
            }`,
          }}
        >
          {data.index + 1}
        </div>
        {data?.orientation === "Right" ? (
          <ThreeDViewRight className='three-d-view' />
        ) : null}
        {data?.orientation === "Left" ? (
          <ThreeDViewLeft className='three-d-view' />
        ) : null}
      </div>
    </div>
  );
}

export default ImageCard;
