import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllPin, setOneDeparture } from "../../redux/slices/routeData";
import styles from "./listCard1.module.scss";

const ListCard1 = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPinPoints = async () => {
    dispatch(setOneDeparture(props?.data));
    await getAllPin(props?.data?.id);
    if (props.windowWidth < 600) navigate(`/map/${props?.data?.id}`);
    if (props?.showSideImages && props?.windowWidth > 600) {
      props.showSideImages();
    }
  };
  const routeInfo = props?.data?.route_information
    ? props?.data?.route_information?.split("/")
    : [];
  return (
    <div
      className={styles.bottomCardWarpper}
      onClick={props?.fromMap ? null : getPinPoints}
    >
      <p className="text-l mb-0 ellipse-text">{props?.data?.departure_point}</p>
      <p className="text-m mb-0 ellipse-text">
        {props?.data?.departure_point_english}
      </p>
      <div className="devider my-1"></div>
      <div className="d-flex align-items-center justify-content-between gap-1">
        {routeInfo ? (
          <div className="w-30">
            <p className="text-s-bold mb-0">{routeInfo[0]}</p>
            <p className="text-s-bold mb-0">{routeInfo[1]}</p>
          </div>
        ) : null}
        {(props?.data?.exit_landmark_local === "" ||
          props?.data?.exit_landmark_local === "N/A") &&
        (props?.data?.exit_landmark_english === "" ||
          props?.data?.exit_landmark_english === "N/A") ? null : (
          <div className="destination-exit text--bold w-70">
            <p className="mb-0 ellipse-text">
              {props?.data?.exit_landmark_local}
            </p>
            <p className="mb-0 ellipse-text">
              {props?.data?.exit_landmark_english}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListCard1;
