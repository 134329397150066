import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, MarkerClusterer, DirectionsRenderer, OverlayView, MarkerLabel } from '@react-google-maps/api'
import Marker from './Marker'
// import { ReactComponent as Start } from '../../assets/svgs/start.svg'
import { ReactComponent as TermsOfServiceSvg } from "../../assets/svgs/iacceptTerms.svg";

import Loader from '../Loader/Loader'
import ListCard from '../ListCard/ListCard'
import LocationIcon from '../../assets/svgs/endPoint.svg'
import startingPoint from '../../assets/svgs/startingPoint.svg'
import './Marker.scss'
import { shallowEqual, useSelector } from 'react-redux'
import ListCard1 from '../ListCard/ListCard1'
import styles from './mapComponent.module.scss'

const containerStyle = {
  width: 'inherit',
  height: 'inherit',
  position: 'relative',
}

const GoogleMapComponent = ({
  showSideImages,
  allPinPoints,
  center,
  departure,
  setShowCarousel,
  windowWidth,
  setActiveTab,
  selectedPin,
  setSelectedPin,
  showCarousel = false,
  destination,
  imageRef = { current: [] },
  mapContainerStyle,
}) => {
  const handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const { onedestination, departures } = useSelector((state) => ({
    onedestination: state.routeData.onedestination,
    departures: state.routeData.departures,
    shallowEqual,
  }))
  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
      scrollwheel: true,
      draggable: true,
      gestureHandling: 'greedy',
    }),
    []
  )
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })

  const [directions, setDirections] = useState()
  const mapRef = useRef()
  const onLoad = useCallback((map) => (mapRef.current = { ...map, zoom: 8 }), [])

  const fetchDirections = () => {
    const service = new window.google.maps.DirectionsService()
    service.route(
      {
        origin: {
          lat: departure?.departure_lat,
          lng: departure?.departure_lng,
        },
        destination: destination,
        travelMode: departure?.transpotation_mode || window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
        waypoints: departure?.path_lat_long_array,
      },
      (result, status) => {
        if (status === 'OK' && result) {
          setDirections(result)
        } else {
          setDirections()
        }
      }
    )
  }
  useEffect(() => {
    if (departure?.departure_lat && departure?.departure_lng) {
      // fetchDirections();
    } else {
      // setDirections();
    }
  }, [departure?.departure_lat, departure?.departure_lng])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle ? mapContainerStyle : containerStyle}
      center={center}
      onLoad={onLoad}
      options={options}
      zoom={18}>
      {!showCarousel && !selectedPin ? (
        <div className='googlemap'>
          {Object.keys(departure).length > 0 ? (
            <div className='list-card-container'>
              <div className={styles.cardsWrapper}>
              <TermsOfServiceSvg
                onClick={() => {
                  setSelectedPin(allPinPoints[0]?.id)
                  if (windowWidth < 600 && setShowCarousel) {
                    setShowCarousel(true)
                    setActiveTab(0)
                  }
                }}
              />
              </div>
              <ListCard1
                data={departure}
                windowWidth={windowWidth}
                showSideImages={showSideImages}
                fromMap
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <MarkerF
        position={destination}
        icon={LocationIcon}
      />
      {directions ? (
        <MarkerF
          position={{
            lat: departure?.departure_lat,
            lng: departure?.departure_lng,
          }}
          icon={startingPoint}
        />
      ) : null}
      {allPinPoints ? (
        <MarkerClusterer>
          {(clusterer) =>
            allPinPoints.map((pin, index) => (
              <OverlayView
                key={pin?.id}
                position={pin?.pinPosition}
                clusterer={clusterer}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <Marker
                  index={index + 1}
                  pinId={pin.id}
                  selectedPin={selectedPin}
                  setDirection={() => {
                    setSelectedPin(pin?.id)
                    if (showSideImages) showSideImages()
                    if (windowWidth < 600 && setShowCarousel) {
                      setShowCarousel(true)
                      setActiveTab(index)
                    }
                    if (imageRef.current.length > 0) handleScrollTo(imageRef.current[index])
                  }}
                />
              </OverlayView>
            ))
          }
        </MarkerClusterer>
      ) : null}
    </GoogleMap>
  ) : (
    <Loader />
  )
}

export default React.memo(GoogleMapComponent)
