import { combineReducers } from "redux";
import routeReducer from "./slices/routeData";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const routeDataPersistConfig = {
  key: "routeData",
  storage,
  keyPrefix: "redux-",
  whitelist: ["onedestination"],
};

const rootReducer = combineReducers({
  routeData: persistReducer(routeDataPersistConfig, routeReducer),
});

export { rootPersistConfig, rootReducer };
