import React from "react";
import "./NoData.scss";
import Nodata from "../../assets/images/noData.jpg";
function NodataFound() {
  return (
    <div className='no-data-container'>
      <img src={Nodata} alt='noData' width={300} />
    </div>
  );
}

export default NodataFound;
