import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ReactComponent as Dots } from '../../assets/svgs/dots.svg'
import { ReactComponent as Location } from '../../assets/svgs/locationImg.svg'
import { ReactComponent as Person } from '../../assets/svgs/walkingManImg.svg'
import ListCard from '../../components/ListCard/ListCard'
import Loader from '../../components/Loader/Loader'
import { webAppTitle, terms_and_condition } from '../../staticData/StaticData'
import styles from './Home.module.scss'

import { departingFrom, destination, points } from '../../staticData/StaticData'

import logo from './logo.png'

function RouteList({ windowWidth, showSideImages }) {
  const { onedestination, departures } = useSelector((state) => ({
    onedestination: state.routeData.onedestination,
    departures: state.routeData.departures,
    shallowEqual,
  }))

  const [DeparturesData, setDeparturesData] = useState([])

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }

  useEffect(() => {
    if (departures.length) {
      setDeparturesData(departures.filter((ele) => ele.pinlength))
    }
  }, [departures])

  return (
    <div className='route_main'>
      <div
        className='d-flex justify-content-between align-items-center header'
        style={{}}>
        <img
          onClick={onClickUrl('https://www.howcanigetthere.com')}
          src={logo}
          width='auto'
          height='15'
        />
        {/* <p className='title1 text-left mb-0'>{webAppTitle}</p> */}
        <div className='routePersonLogo m-0'>
          <Person />
        </div>
        <p
          onClick={onClickUrl('https://www.howcanigetthere.com/tos')}
          className=' text-left mb-0 '
          style={{ fontSize: '12px' }}>
          {terms_and_condition}
        </p>
      </div>
      {/* <p className='text-m text-center mb-1 ms-2'>{destination}</p> */}
      <div className='destination-container'>
        <p className='text-m mb-1 ms-2'>{destination}</p>
        <div className='d-flex align-items-center destination-title'>
          <div className='location-logo'>
            <Location width={30} />
          </div>
          <div>
            <p className='text-s mb-0 '>{onedestination.destination_japan}</p>
            <p className='text-s mb-0 '>{onedestination.destination_english}</p>
          </div>
        </div>
        <div className='mt-1 ms-3 mb-1'>
          <Dots />
          {/* <p className='mb-0 d-inline-block ms-3 text-s'>4件</p> */}
        </div>
        <div className='d-flex align-items-center justify-content-between mt-2'>
          <p className='text-m mb-0 ms-2'> {departingFrom}</p>
          <p className='text-m mb-0 ms-2'>
            {DeparturesData.length} {points}
          </p>
        </div>
        <div
          className='overflow-auto route-listing'
          style={{}}>
          {departures.length ? (
            DeparturesData.length > 0 ? (
              DeparturesData.map((routes, index) => {
                return index === departures.length - 1 ? (
                  <div className={styles.homeListCard}>
                    <ListCard
                      key={index}
                      data={routes}
                      windowWidth={windowWidth}
                      showSideImages={showSideImages}
                    />
                    <div style={{ height: '100px' }}></div>
                  </div>
                ) : (
                  <div className={styles.homeListCard}>
                    <ListCard
                      key={index}
                      data={routes}
                      windowWidth={windowWidth}
                      showSideImages={showSideImages}
                    />
                  </div>
                )
              })
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10vh' }}>No Data Found</div>
            )
          ) : (
            ''
            // <Loader />
          )}
        </div>
      </div>
    </div>
  )
}

export default RouteList
