import { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import './App.scss'
import './assets/Typography.scss'
import Navbar from './components/Navbar/Navbar'
import Page404 from './page404/Page404'
import Home from './pages/Home/Home'
import List from './pages/List'
import Map from './pages/Map'
import { setOneDestination } from './redux/slices/routeData'
import usePageTracking from './utils/usePageTracking'

function App() {
  usePageTracking()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    dispatch(setOneDestination({}))
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  // useEffect(() => {
  //   if (windowWidth > 600) {
  //     console.log("onedestination", onedestination);
  //     navigate(`/${onedestination.id}`);
  //   }
  // }, [windowWidth]);

  return (
    <div className='App'>
      <div className='main'>
        <Routes>
          <Route
            exact
            path='/:id'
            element={<Home windowWidth={windowWidth} />}
          />
          <Route
            path='/map/:id'
            exact
            element={<Map windowWidth={windowWidth} />}
          />
          <Route
            path='/list/:id'
            exact
            element={<List />}
          />
          <Route
            element={<Page404 />}
            path='/404'
          />
        </Routes>
        <Navbar />
      </div>
    </div>
  )
}

export default App
