import React from "react";
import ImageCard from "../../components/ImageCard/ImageCard";

function ImageList({ allPinPoints, imageRef, selectedPin = "", cardClick }) {
  return (
    <>
      <div className='overflow-auto image-listing'>
        {allPinPoints.map((pin, index) => {
          return (
            <div
              className='list-card'
              key={index}
              id={pin?.id}
              ref={imageRef.current[index]}
              onClick={() => cardClick(pin)}
            >
              <ImageCard
                data={{ ...pin, index }}
                {...(selectedPin ? { selectedPin: selectedPin } : {})}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ImageList;
