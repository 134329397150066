import React, { useEffect, useMemo, useState } from 'react'
import Carousel from '../components/Carousel/Carousel'
import ImageCard from '../components/ImageCard/ImageCard'
import GoogleMapComponent from '../components/GoogleMapComponent/GoogleMapComponent'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllPin, getOneDeparture, setSelectedPin } from '../redux/slices/routeData'
import { BottomSheet } from 'react-spring-bottom-sheet'
import styles from "./map.module.scss";

const Map = ({ windowWidth }) => {
  const dispatch = useDispatch()
  const { allPinPoints, onedestination, onedeparture, selectedPin } = useSelector(
    (state) => ({
      allPinPoints: state.routeData.allPinPoints,
      onedestination: state.routeData.onedestination,
      onedeparture: state.routeData.onedeparture,
      selectedPin: state.routeData.selectedPin,
    }),
    shallowEqual
  )
  const params = useParams()
  useEffect(() => {
    getOneDeparture(params?.id)
    getAllPin(params?.id)
  }, [params?.id])

  const [initialSlide, setInitialSlide] = useState(0)
  const [showCarousel, setShowCarousel] = useState(false)

  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  const component = (item, index) => {
    return (
      <ImageCard
        data={{ ...item, index }}
        {...(selectedPin ? { selectedPin: selectedPin } : {})}
      />
    )
  }
  const setTab = (tab) => {
    setInitialSlide(tab)
  }

  const currentCenter = useMemo(() => {
    if (selectedPin) {
      const selectedPinDetails = allPinPoints.find((item) => item.id === selectedPin)
      return selectedPinDetails?.pinPosition
    } else {
      if (allPinPoints.length > 0) {
        return allPinPoints[0].pinPosition
      } else {
        return {
          lat: onedestination?.destination_lat,
          lng: onedestination?.destination_lng,
        }
      }
    }
  }, [selectedPin, allPinPoints, onedestination])

  useEffect(() => {
    if (selectedPin) {
      const index = allPinPoints.findIndex((item) => item.id === selectedPin)
      if (index > -1) {
        setInitialSlide(index)
      }
    }
  }, [showCarousel])

  useEffect(() => {
    if (selectedPin) {
      const index = allPinPoints.findIndex((item) => item.id === selectedPin)

      if (index > -1) {
        setShowCarousel(true)
        setInitialSlide(index)
      } else {
        setShowCarousel(false)
        dispatch(setSelectedPin(null))
      }
    }
  }, [allPinPoints])

  useEffect(() => {
    setShowCarousel(false)
  }, [])

  const handleCancel = () => {
    setShowCarousel(false)
    dispatch(setSelectedPin(null))
  }

  useEffect(() => {
    if (windowWidth > 600) {
      setShowCarousel(false)
    }
  }, [windowWidth])

  return (
    <div className={styles.mapContainer}>
      <GoogleMapComponent
        allPinPoints={allPinPoints}
        center={currentCenter}
        destination={{
          lat: onedestination?.destination_lat,
          lng: onedestination?.destination_lng,
        }}
        zoom={8}
        mapContainerStyle={{
          width: '100%',
          position: 'fixed',
          height: showCarousel ? '100%' : '100%',
          maxHeight: '100%',
          flex: '1',
        }}
        departure={onedeparture}
        setShowCarousel={setShowCarousel}
        setActiveTab={setTab}
        selectedPin={selectedPin}
        setSelectedPin={(id) => dispatch(setSelectedPin(id))}
        windowWidth={windowWidth}
        showCarousel={showCarousel}
      />
      <div className={showCarousel || !selectedPin ? 'dnone' : ''}>
        <button
          className='tooglebntn'
          onClick={() => setShowCarousel(true)}>
          <div className='line'></div>
          <span>show</span>
        </button>
      </div>
      {showCarousel ? (
        <BottomSheet
          open={showCarousel}
          expandOnContentDrag={false}
          onDismiss={() => {
            setShowCarousel(false)
          }}
          snapPoints={({ maxHeight }) => [maxHeight * 0.35, maxHeight * 0.35]}
          defaultSnap={({ lastSnap, snapPoints }) => {
            lastSnap ?? Math.max(...snapPoints)
          }}
          blocking={false}>
          <div className='closebtn'>
            <button
              className='closetoggle'
              onClick={() => setShowCarousel(false)}>
              <span>show</span>
            </button>
          </div>
          <Carousel
            navigation={true}
            data={allPinPoints}
            renderComponent={component}
            initialSlide={initialSlide}
            setSelectedPin={(id) => dispatch(setSelectedPin(id))}
            cancel={() => handleCancel()}
          />
        </BottomSheet>
      ) : null}
    </div>
  )
}

export default Map
